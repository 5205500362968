<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      title="厅房详情"
    >
      <template slot="footer">
        <a-button key="submit" type="primary" :loading="loading" @click="handleOk">
          确定
        </a-button>
      </template>
      <a-card :title="data.roomName" :bordered="false" class="hall-name">
        <p>{{ data.roomDesc }}</p>
      </a-card>
      <img
        style=" margin-bottom: 30px; margin-left: 5%;width: 90%;"
        :src="data.roomPic"
      >
    </a-modal>
  </div>
</template>

<script>
import { findHallsById } from '@/api/hall'

export default {
  name: 'HallDetail',
  components: {},
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      data: {
        loading: false
      }
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loading = true
      findHallsById({ hall_id: this.id }).then((res) => {
        if (res.code === '0') {
          this.data = res.data
        }
      })
      this.loading = false
    },
    handleOk() {
      this.isShow = false
    }
  }
}
</script>
<style lang="less" scoped>
/deep/ .hall-name {
  .ant-card-head-title {
    display: flex;
    justify-content: center;
  }
}
</style>
